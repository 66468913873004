import {NEWS_CONST} from "../constants";

export const initialState = {
    news: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    newsBlock: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NEWS_CONST.GET_NEWS:
            return {
                ...state,
                news: {
                    count: action.payload.data.count,
                    itemsList:action.payload.reset ? action.payload.data.items : [...state.data.news, ...action.payload.data.items],
                    hasMore: action.payload.hasMore
                },
            };
        case NEWS_CONST.GET_NEWS_BLOCK:
            return {
                ...state,
                newsBlock: action.payload,
            };
        default:
            return state;
    }
};
