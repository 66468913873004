import './contact.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetContacts,SendEmail} from "../../redux/actions";
import {connect} from "react-redux";
import {
    AboutPageImg,
    AboutResponsiveImg, ContactAddressIcon,
    ContactCubLIcon,
    ContactCubSIcon,
    ContactEmailIcon, ContactPhoneIcon, ContactResponsiveImg, HeaderArrIcon, IlContactIcon
} from "../../assets/images";
import PageHeader from "../../components/pageHeader/PageHeader";
import {useEffect,useState} from "react";
// import {LoadingOutlined} from "@ant-design/icons";
import {InputGroup} from "../../components/uiElements/input-group/inputGroup";
import isEmail from "validator/es/lib/isEmail";

function Contact(props){
    const {contacts,requestLoading,staticTexts} = props
    const [fieldsData,setFieldsData] = useState({
        firstname:'',
        lastname:'',
        email:'',
        phone:'',
        message:'',
    })
    const [error,setError] = useState({
        firstname:false,
        lastname:false,
        email:false,
        phone:false,
        message:false,
    })
    const contactHeader = {
        responsiveImg:ContactResponsiveImg,
        subTitle:`${staticTexts?.contact_page_subtitle}`,
        title:`${staticTexts?.contact_page_title}`,
        description:`${staticTexts?.contact_page_description}`,
    }
    useEffect(() => {
        props.GetContacts()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }
    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    lastname:'',
                    email:'',
                    phone:'',
                    message:'',
                })
            })
        }
    }
    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={'contacts-wrapper'}>
        <PageHeader data={contactHeader} icon={<IlContactIcon/>}/>
        <div className={'contact-info-wrapper'}>
            <div className={'contact-large-img'}>
                <ContactCubLIcon/>
            </div>
            <div className={'contact-small-img'}>
                <ContactCubSIcon/>
            </div>
            <div className={'contact-info'}>
                <div className={'contact-email-block'}>
                    <a href={`mailto:${contacts?.email}`} className={'email-block-img'}>
                        <ContactEmailIcon/>
                    </a>
                    <div className={'info-block-title'}>
                        {staticTexts?.contact_page_email_title}
                    </div>
                    <div className={'info-block-description'}>
                        {staticTexts?.contact_page_email_description}
                    </div>
                    <a href={`mailto:${contacts?.email}`} className={'info-block'}>
                        <span>{contacts?.email}</span>
                    </a>
                </div>
                <div className={'contact-address-block'}>
                    <div className={'info-block-img'}>
                        <ContactAddressIcon/>
                    </div>
                    <div className={'info-block-title'}>
                        {staticTexts?.contact_page_address_title}
                    </div>
                    <div className={'info-block-description'}>
                        {contacts?.address}
                    </div>
                    <div className={'info-block'}>
                        <span>{staticTexts?.contact_page_address_map}</span>
                    </div>
                </div>
                <div className={'contact-phone-block'}>
                    <a href={`tel:${contacts?.phoneNumber}`} className={'info-block-img'}>
                        <ContactPhoneIcon/>
                    </a>
                    <div className={'info-block-title'}>
                        {staticTexts?.contact_page_phone_title}
                    </div>
                    <div className={'info-block-description'}>
                        {staticTexts?.contact_page_work_time}
                    </div>
                    <a href={`tel:${contacts?.phoneNumber}`} className={'info-block'}>
                       <span>{contacts?.phoneNumber}</span>
                    </a>
                </div>
            </div>
        </div>
        <div className={'contact-input-wrapper'}>
            <div className={'input-wrapper-subtitle'}>
                {staticTexts?.contact_page_inputs_subtitle}
            </div>
            <div className={'input-wrapper-title'}>
                {staticTexts?.contact_page_inputs_title}
            </div>
            <div className={'input-wrapper-description'}>
                {staticTexts?.contact_page_inputs_description}
            </div>
            <div className={'contacts-inputs'}>
                <div className={'fullName-inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.firstname}
                        error={error.firstname}
                        name={'firstname'}
                        placeholder={staticTexts?.contacts_page_placeholder_name}
                        label={staticTexts?.contacts_page_label_name}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.lastname}
                        error={error.lastname}
                        name={'lastname'}
                        placeholder={staticTexts?.contacts_page_placeholder_lastName}
                        label={staticTexts?.contacts_page_label_lastName}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                </div>
                <div className={'inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.email}
                        error={error.email}
                        name={'email'}
                        placeholder={staticTexts?.contacts_page_placeholder_email}
                        label={staticTexts?.contacts_page_label_email}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.phone}
                        error={error.phone}
                        name={'phone'}
                        label={staticTexts?.contacts_page_label_phoneNumber}
                        placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        value={fieldsData.message}
                        error={error.message}
                        name={'message'}
                        placeholder={staticTexts?.contacts_page_placeholder_message}
                        label={staticTexts?.contacts_page_label_message}
                        onChange={getInputValues}
                    />
                </div>
                <button className='sent-email'
                        onClick={() => {
                            if (!requestLoading && sendVisit) {
                                sendVisit()
                            }
                        }}>
                    {staticTexts?.contacts_page_btn_send}
                    {/*{*/}
                    {/*    requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>*/}
                    {/*        : ''*/}
                    {/*}*/}

                </button>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'contacts',
        'requestLoading',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetContacts,
    SendEmail
};


export default  connect(mapStateToProps, mapDispatchToProps)(Contact);