import {
    request,
    _urlStaticTexts,
    _urlPartners,
    _urlLanguage,
    _urlCountryCode1, _urlHome, _urlOrder, _urlDistribution, _urlOurProgram,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";
import {store} from "../store";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

const _GetCountryCode1 = () => {
    const requestData = {
        url: _urlCountryCode1,
        method: "GET",
    };
    return request(requestData)
        .then((res) => {
            const data = res && res.data;
            const code = data?.countryCode === 'AM' ? 'HY' : data?.countryCode
            return code?.toLowerCase() || 'HY'
        }).catch(_ => 'en')
};


export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                // const countryCode = await _GetCountryCode1()
               // const countryCode = await _GetCountryCode2()
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: { /*countryCode, */  languages: data }
                })
            })
    };
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                const { selectedLanguage } = store?.getState()?.utils || {}

                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: {data, selectedLanguage}
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};


export const GetPartners = () => {
    const requestData = {
        url: `${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};

export const GetDistribution = () => {
    let url = `${_urlDistribution}`;
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_DISTRIBUTION,
                    payload:data
                })
            })
    };
};

export const shopProduct = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.SHOP_PRODUCT,
            payload: data
        })
    }
};

export const isOpen = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.IS_OPEN,
            payload: data
        })
    }
};
export const sendOrder = (data) => {
    const requestData = {
        url: `${_urlOrder}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const GetHome = () => {
    const requestData = {
        url: `${_urlHome}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_HOME,
                    payload: data
                })
            })
    };
};
export const GetOurProgram = () => {
    const requestData = {
        url: `${_urlOurProgram}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_OUR_PROGRAMS,
                    payload: data
                })
            })
    };
};