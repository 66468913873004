import './projectBlock.scss'
import {F151Img, F159Img, F160Img, F161Img} from "../../../assets/images";
import {SliderNextButton, SliderPrevButton} from "../../uiElements/buttons/Buttons";
import {useRef, useState} from "react";
import Slider from "react-slick";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";
import {Link} from "react-router-dom";

function ProjectBlock(props){
    const {distribution,staticTexts,about} = props
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (distribution?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };

    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode:  window.innerWidth < 700,
        centerPadding: '60px 0 0',
        ref: sponsors => (sliderRef = sponsors),
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '40px 0 40px',
                }
            },
        ]
    };

    return <div className={'projectBlock-wrapper'}>
        <div className={'projectBlock-header'}>
            <div className={'projectBlock-left'}>
                <div className={'projectBlock-title'}>
                    {staticTexts?.homepage_distribution_title}
                </div>
                <div className={'projects-block-title'}>
                    {staticTexts?.homepage_distribution_block_title}
                </div>
                <a href={'https://tvradio.am/type/distributors/'} target={'_blank'} className={'projects-block-btn'}>
                    {staticTexts?.homepage_service_link}
                </a>
            </div>
            <div className={'projectBlock-right'}>
                <div className={'projectBlock-description'}>
                    {staticTexts?.homepage_distribution_description}
                </div>
                <div className={'projectBlock-info'}>
                    <div className={'projectBlock-films'}>
                        <span>{about[0]?.count} +</span>
                        <p>{about[0]?.title}</p>
                    </div>
                    <span className={'projectBlock-line'}/>
                    <div className={'projectBlock-subscribers'}>
                        <span>{about[1]?.count}%</span>
                        <p>{about[1]?.title}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={'projects-block'}>
            <div className={'projects'}>
                {
                    <Slider {...settings}>
                        {distribution?.length && distribution.map(item =>{
                            return <a href={`${item?.link}`} target={'_blank'} className="projects-item" key={item.id}>
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="projects-image"/>
                            </a>
                        })}
                    </Slider>
                }
                {!!distribution?.length < 4 && <>
                    <SliderPrevButton  cbPrev={() => prevSlide(slideIndex)}/>
                    <SliderNextButton  cbNext={() => nextSlide(slideIndex)}/>
                </>}
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'distribution',
        'staticTexts',
        'about',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(ProjectBlock);