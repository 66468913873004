import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";
import {LOCALES} from "../../constants/constType";

const pathname = window.location.pathname

const pathLocal = pathname?.split('/')[1]
let selectedLanguage = ''
if (pathLocal && LOCALES.includes(pathLocal)) {
    selectedLanguage=pathLocal;
}else{
    selectedLanguage= "hy"
}
console.log('selectedLanguage general', selectedLanguage)

export const initialState = {
    requestLoading: false,
    selectedLanguage: selectedLanguage,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };


        case UTIL_CONSTS.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case UTIL_CONSTS.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
        default:
            return state;
    }
}