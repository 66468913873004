// Import packages
import React, {useEffect} from "react";
import {Route, Routes, Navigate, Redirect, useLocation, useHistory} from "react-router-dom";
import Layout from "../containers/Layout";
import Homepage from "../containers/homepage/Homepage";

// Import utils
import { history } from "../configs/history";
import { store } from "../redux/store";

// Import pages
import Services from "../containers/service/Service";
import About from "../containers/about/About";
import News from "../containers/news/News";
import Contact from "../containers/contact/Contact";
import {getPropsFromState} from "../redux/mapStateToProps";
import {ChangeLanguage} from "../redux/actions";
import {connect} from "react-redux";



 function RoutesBlocks() {
    const location = useLocation()
    const path = location.pathname
    const reduxStore = store?.getState() || {}
    const { selectedLanguage } = reduxStore.utils || {}
     if (!path || path === '/') {
         console.log("selectedLanguage rout", path);
         // history.replace(`/${selectedLanguage}/home`)
         history.push(`/${selectedLanguage}/home`)
         // return null
     }
    let locale = selectedLanguage;

    // console.log('path rout', path,)
    return <Layout>
        <Routes>
            <Route index element={<Homepage/>} />
            {/*<Route path={`/${locale}`} element={<Homepage/>}/>*/}
            <Route path={`/${locale}/home`} element={<Homepage/>}/>
            <Route path={`/${locale}/services`} element={<Services/>}/>
            <Route path={`/${locale}/about`} element={<About/>}/>
            <Route path={`/${locale}/news`} element={<News/>}/>
            <Route path={`/${locale}/contact`} element={<Contact/>}/>
        </Routes>
    </Layout>
}
export default RoutesBlocks;


