import './news.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetNews, GetNewsBlock} from "../../redux/actions";
import {connect} from "react-redux";
import {
    ArrLeftIcon, ArrRightIcon,
    IlInfoIcon,
    NewsResponsiveImg
} from "../../assets/images";
import PageHeader from "../../components/pageHeader/PageHeader";
import {useEffect, useRef, useState} from "react";
import NewsCard from "../../components/uiElements/card/newsCard/NewsCard";
import React from "react";
import {PAGE_NEWS_COUNT} from "../../constants/constType";
import {Pagination} from "antd";

function News(props) {
    const {news, newsBlock, staticTexts} = props
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    const newsHeader = {
        responsiveImg: NewsResponsiveImg,
        subTitle: `${staticTexts?.news_page_subtitle}`,
        title: `${staticTexts?.news_page_title}`,
        description: `${staticTexts?.news_page_description}`,
    }

    useEffect(() => {
        props.GetNews()
        props.GetNewsBlock()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    async function onPageChanged(news) {
        let count = news - 1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: count * PAGE_NEWS_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetNews(configInfo)
        setCurrentPage(news);
        window.scrollTo({
            top: 0
        });
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <ArrLeftIcon className={'arrow_icon'}/>
                {/*<span>*/}
                {/*    {staticTexts?.blog_page_prev}*/}
                {/*</span>*/}
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                {/*<span>*/}
                {/*    {staticTexts?.blog_page_next}*/}
                {/*</span>*/}
                <ArrRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };


    return <div className={'news-wrapper'}>
        <PageHeader data={newsHeader} icon={<IlInfoIcon/>}/>
        <div className={'news-block'}>
            <div className={'news-subtitle'}>
                <span>{staticTexts?.news_page_block_subtitle}</span>
            </div>
            <div className={'news-title'}>
                {staticTexts?.news_page_block_title}
            </div>
            <div className={'news-description'}>
                {staticTexts?.news_page_block_description}
            </div>
            <div className={'news-slider'}>
                {news?.itemsList?.length && news?.itemsList?.map(item => {
                    return <NewsCard item={item}/>
                })}

                <Pagination current={currentPage}
                            onChange={onPageChanged}
                            simple={false}
                            total={news?.count}
                            defaultPageSize={6}
                            hideOnSinglePage={true}
                            itemRender={itemRender}
                            className={`pagination`}
                />
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'news',
        'newsBlock',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetNews,
    GetNewsBlock
};


export default connect(mapStateToProps, mapDispatchToProps)(News);