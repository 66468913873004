import './footer.scss'
import {Link, NavLink} from "react-router-dom";
import {EmailIcon, Logo, PhoneIcon, SolidyIcon} from "../../assets/images";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {generateImageMediaUrl} from "../../utils/generateImageMediaUrl";

function Footer(props){
    const {contacts,staticTexts} = props

    // function downloadPresentation () {
    //     const file = contacts?.presentationFile;
    //     console.log(generateImageMediaUrl(file?.path),'getUrlWithLocal(file?.path)')
    //     if (file) {
    //         fetch(generateImageMediaUrl(file?.path))
    //             .then(response => {
    //                 response.blob().then(blob => {
    //                     let a = document.createElement('a');
    //                     a.href = window.URL.createObjectURL(blob);
    //                     a.download = file?.name;
    //                     a.click();
    //                 });
    //             });
    //     }
    // }

    function downloadPresentation(pdf) {
        console.log(pdf,'pdf')
        if (pdf) {
            fetch(generateFileMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `${pdf.name}`;
                        a.click();
                    });
                });
        }
    }

    return <div className={'footer-wrapper'}>
        <div className={'footer-top-block'}>
            {
                contacts?.presentationFile && <div className={'footer-pdf-download'} onClick={() => {downloadPresentation(contacts?.presentationFile)}}>
                    {staticTexts?.footer_section_presentationFile_text}
                </div>
            }
            <div className={'footer-left-block'}>
                <Link to={getUrlWithLocal('/home')} className={'footer-logo'}>
                    <Logo/>
                </Link>
                <a href={`tel:${contacts?.phoneNumber}`} className={'footer-contact'}>
                    <PhoneIcon/> {contacts?.phoneNumber}`
                </a>
                <a href={`mailto:${contacts?.email}`} className={'footer-email'}>
                    <EmailIcon/> {contacts?.email}
                </a>
            </div>
            <div className={'footer-right-blocks'}>
                <div className={'footer-info-block'}>
                    <div className={'footer-info-title'}>
                        {staticTexts?.footer_info_title}
                    </div>
                    <NavLink to={getUrlWithLocal('/home')} activeClassName='active'>
                        <li className="nav-item">{staticTexts?.footer_item_header}</li>
                    </NavLink>
                    <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                        <li className="nav-item">{staticTexts?.footer_item_about}</li>
                    </NavLink>
                    <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                        <li className="nav-item">{staticTexts?.footer_item_services}</li>
                    </NavLink>
                    <NavLink to={getUrlWithLocal('/news')} activeClassName='active'>
                        <li className="nav-item">{staticTexts?.footer_item_news}</li>
                    </NavLink>
                    <NavLink to={getUrlWithLocal('/contact')} activeClassName='active'>
                        <li className="nav-item">{staticTexts?.footer_item_contact}</li>
                    </NavLink>
                </div>
                <div className={'footer-info-block'}>
                    <div className={'footer-info-title'}>
                        {staticTexts?.footer_service_title}
                    </div>
                    <NavLink to={getUrlWithLocal('/services')} className={'footer-info'}>
                        {staticTexts?.footer_service_first}
                    </NavLink>
                    <NavLink to={getUrlWithLocal('/services')} className={'footer-info'}>
                        {staticTexts?.footer_service_second}
                    </NavLink>
                </div>
            </div>
        </div>
        <div className={'footer-bottom-block'}>
            <p>{staticTexts?.footer_cooperation}</p>
            <div className={'developed-block'}>
                Developed by <a href={'https://solidy.am/'} target={'_blank'}>Solidy</a>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
    ])
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps,mapDispatchToProps)(Footer);