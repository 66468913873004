import './mainBlock.scss'
import {CubIcon, HeaderArrIcon, OvalIcon, ResponsiveImg} from "../../../assets/images";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";

function MainBlock(props){
    const {home,staticTexts} = props

    return <div className={'mainBlock-wrapper'}>
        <div className={'mainBlock-white'}>
            <div className={'mainBlock-subtitle'}>
                {staticTexts?.homepage_main_subtitle}
            </div>
            <div className={'mainBlock-title'}>
                {staticTexts?.homepage_main_title}
            </div>
            <div className={'mainBlock-description'}>
                {staticTexts?.homepage_main_description}
            </div>
            <a href={home?.link} target={'_blank'} className={'mainBlock-link'}>
                {staticTexts?.homepage_main_link} <HeaderArrIcon/>
            </a>
            <div className={'responsive-img'}>
                <img src={ResponsiveImg} alt=""/>
            </div>
        </div>
        <div className={'mainBlock-black'}>
            <div className={'mainBlock-cub'}>
                <CubIcon/>
            </div>
            <div className={'mainBlock-oval'}>
                <OvalIcon/>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'home',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(MainBlock);