// ----------------------------------4Steps-server---------------------------------------------------
// const BASE_URL =  "http://173.212.233.220:3402/api/v1";
// export const HOST_MEDIA_URL_V1 = "http://173.212.233.220:3403";
// export const WEB_URL = "http://173.212.233.220:3403";


// //----------------------------------Official-server---------------------------------------------------
// const BASE_URL =  "http://37.60.224.224:3102/api/v1";
// export const HOST_MEDIA_URL_V1 = "http://37.60.224.224:3103";

const BASE_URL =  "https://app.hpd.am/front/api/v1";
export const HOST_MEDIA_URL_V1 = "https://app.hpd.am/file";
export const WEB_URL = "https://app.hpd.am/file";


export const _urlLanguage = BASE_URL + "/language";
export const _urlStaticTexts = BASE_URL + "/static-texts";
export const _urlPartners = BASE_URL + "/partner";
export const _urlDistribution = BASE_URL + "/distribution";
export const _urlContacts = BASE_URL + "/contact";
export const _urlCall = BASE_URL + "/call-request";
export const _urlOrder = BASE_URL + "/order-request";
export const _urlNews = BASE_URL + "/news";
export const _urlNewsBlock = BASE_URL + "/news-block";
export const _urlServices = BASE_URL + "/service";
export const _urlOurProgram = BASE_URL + "/our-program";
export const _urlHome = BASE_URL + "/home";
export const _urlAbout = BASE_URL + "/about";
export const _urlCountryCode1 = "http://ip-api.com/json";

