import './programsBlock.scss'
import {HeaderArrIcon, ProgramsCheckIcon} from "../../../assets/images";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";

function ProgramsBlock(props){
    const {ourPrograms,staticTexts} = props

    return <div className={'ProgramsBlock-wrapper'}>
        <div className={'ProgramsBlock-title'}>
            {staticTexts?.homepage_programs_title}
        </div>
        <div className={'ProgramsBlock'}>
            {
                ourPrograms?.length && ourPrograms?.map((item,index) => {
                    return <div className={'programCard'} key={index}>
                        <div className={'programCard-img'}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt=""/>
                        </div>
                        <div className={'programCard-title'}>
                            {item?.title}
                        </div>
                        <div className={'programCard-options-block'}>
                            {
                                item?.options?.map(option => {
                                    return <div className={'programCard-options'}>
                                        <ProgramsCheckIcon/> <span>{option}</span>
                                    </div>
                                })
                            }
                        </div>
                        <a href={item?.link} target={'_blank'} className={'programCard-link'}>
                            {staticTexts?.homepage_programs_contact} <HeaderArrIcon/>
                        </a>
                    </div>
                })
            }
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'ourPrograms',
        'staticTexts',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(ProgramsBlock);