import './infoBlock.scss'
import {DownloadIcon, LinkIcon, NewsBlockIcon} from "../../assets/images";
import React from "react";
import {WEB_URL} from "../../redux/api";
import {useEffect} from "react";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetNews, GetNewsBlock} from "../../redux/actions";
import {connect} from "react-redux";

 function InfoBlocks(props){
    const {newsBlock,staticTexts} = props

    useEffect(() => {
        props.GetNewsBlock()
    }, [])

    function pdfFile(pdf) {
        const pdfLink = `${WEB_URL}/resources/${pdf}`
        if (pdf) {
            window.open(pdfLink, '_blank');
        }
    }
    return <div className={'newsBlock'}>
        {
            newsBlock?.length && <div className={'newsBlock-wrapper'}>
                {
                    newsBlock?.length && newsBlock?.map(item => {
                        return <div className={'newsBlock-card'} key={item?.id}>
                            <div className={'newsBlock-icon'}>
                                <NewsBlockIcon/>
                            </div>
                            <div className={'newsBlock-title'}>
                                {item?.title}
                            </div>
                            <div className={'newsBlock-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                            <div className={'newsBlock-link-wrapper'}>
                                {
                                    item?.links.map((link, index) => {
                                        return <a href={link} target={'_blank'} className={'newsBlock-link'}>
                                            <span><LinkIcon/> {index + 1} {staticTexts?.news_page_link_text}</span>
                                        </a>
                                    })
                                }
                            </div>
                            <div className={'newsBlock-pdf-wrapper'}>
                                {
                                    item?.pdfs.map(pdf => {
                                        return  <div className={'newsBlock-pdf'} onClick={() => pdfFile(pdf?.path)}>
                                            <DownloadIcon/> {staticTexts?.news_page_pdf_text}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        }
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'news',
        'newsBlock',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetNews,
    GetNewsBlock
};


export default connect(mapStateToProps, mapDispatchToProps)(InfoBlocks);