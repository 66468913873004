import './about.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import PageHeader from "../../components/pageHeader/PageHeader";
import {AboutPageImg, AboutResponsiveImg, IlInfoIcon} from "../../assets/images";
import {useEffect} from "react";
import {GetAbout} from "../../redux/actions";
import ProjectBlock from "../../components/homepage/projectBlock/ProjectBlock";
import ProgramsBlock from "../../components/homepage/programsBlock/ProgramsBlock";
import InfoBlocks from "../../components/infoBlocks/InfoBlocks";

function About(props) {
    const {about,staticTexts} = props
    const aboutHeader = {
        responsiveImg:AboutResponsiveImg,
        subTitle:`${staticTexts?.about_page_subtitle}`,
        title:`${staticTexts?.about_page_title}`,
        description:`${staticTexts?.about_page_description}`,
    }

    useEffect(() => {
        props.GetAbout()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'about-wrapper'}>
        <div className={'about-blocks'}>
            <PageHeader data={aboutHeader}/>
            <div className={'about-blogs-wrapper'}>
                {
                    about?.length && about?.map(item => {
                        return <div className={'about-block'} key={item?.id}>
                            <div className={'about-count-block'}>
                                <div className={'about-count'}>
                                    {
                                        item?.count === 100 ? <p>{item?.count}%</p> : <p>{item?.count}+</p>
                                    }
                                    <span>{item?.title}</span>
                                </div>
                            </div>
                            <div className={'about-info-block'}>
                                {item?.description}
                            </div>
                        </div>
                    })
                }
            </div>
            <ProgramsBlock/>
            <InfoBlocks/>
        </div>
        <ProjectBlock/>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetAbout
};


export default  connect(mapStateToProps, mapDispatchToProps)(About);