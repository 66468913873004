import './partnerBlock.scss'
import {EllipseIcon, PartnersImg, PartnersSquareIcon} from "../../../assets/images";
import {useRef, useState} from "react";
import Slider from "react-slick";
import {SliderNextButton, SliderPrevButton} from "../../uiElements/buttons/Buttons";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";

function PartnerBlock(props){
    const {partners,staticTexts} = props
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (partners?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };

    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        fade:true,
        arrows: false,
        autoplay:false,
        infinite:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: partners => (sliderRef = partners),
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    fade:false,
                    // centerMode:  window.innerWidth < 700,
                    // centerPadding: '0 0 60px',
                }
            },
        ]
    };

    return <div className={'partnerBlock-wrapper'}>
        <div className={'partnerBlock-header'}>
            <div className={'partnerBlock-icon'}>
                <PartnersSquareIcon/>
            </div>
            <div className={'partnerBlock-subtitle'}>
                {staticTexts?.homepage_partner_subtitle}
            </div>
            <div className={'partnerBlock-title'}>
                {staticTexts?.homepage_partner_title}
            </div>
            <div className={'partnerBlock-description'}>
                {staticTexts?.homepage_partner_description}
            </div>
        </div>
        <div className={'partnerBlock'}>
            {
                <Slider {...settings}>
                    {partners?.length && partners.map(item =>{
                        return <div className="partners-item" key={item.id}>
                            <div className={'partners-img-wrapper'}>
                                <div className={'partners-imgBg'}>
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt=""/>
                                </div>
                            </div>
                            <div className={'partners-info'}>
                                <EllipseIcon/>
                                <div className={'partners-title'}>
                                    {item?.title}
                                </div>
                                <div className={'partners-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                                <a href={item?.link}  target={'_blank'} className={'partners-more'}>
                                    {staticTexts?.homepage_partner_more}
                                </a>
                            </div>
                        </div>
                    })}
                </Slider>
            }
            {<>
                <SliderPrevButton  cbPrev={() => prevSlide(slideIndex)}/>
                <SliderNextButton  cbNext={() => nextSlide(slideIndex)}/>
            </>}
        </div>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
        'staticTexts',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(PartnerBlock);