import './serviceBlock.scss'
import {BlackEllipseIcon, EllipseIcon, HalfEllipseIcon, PlanetsGroupIcon} from "../../../assets/images";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";

function ServiceBlock(props){
    const {services,staticTexts} = props

    return <div className={'serviceBlock-wrapper'}>
        <div className={'serviceBlock-responsiveImg'}>
            <PlanetsGroupIcon/>
        </div>
        <div className={'serviceBlock-header'}>
            <div className={'serviceBlock-header-subtitle'}>
                {staticTexts?.homepage_service_subtitle}
            </div>
            <div className={'serviceBlock-header-title'}>
                {staticTexts?.homepage_service_title}
            </div>
        </div>
        <div className={'serviceBlocks'}>
            <div className={'serviceFirstCard'}>
                <div className={'serviceFirstCard-topImg'}>
                    <HalfEllipseIcon/>
                </div>
                <div className={'serviceFirstCard-left'}>
                    <div className={'serviceFirstCard-title'}>
                        {services[0]?.title}
                    </div>
                    <div className={'serviceFirstCard-description'}>
                        {services[0]?.subTitle}
                    </div>
                    <Link to={getUrlWithLocal('services')} className={'serviceFirstCard-more'}>
                        {staticTexts?.homepage_service_link}
                    </Link>
                </div>
                <div className={'serviceFirstCard-right'}>
                    <div className={'serviceFirstCard-img'}>
                        <PlanetsGroupIcon/>
                    </div>
                </div>
            </div>
            <div className={'serviceCard'}>
                <div className={'serviceCard-icon'}>
                    <EllipseIcon/>
                </div>
                <div className={'serviceCard-title'}>
                    {services[1]?.title}
                </div>
                <div className={'serviceCard-description'}>
                    {services[1]?.subTitle}
                </div>
                <Link to={getUrlWithLocal('services')} className={'serviceCard-more'}>
                    {staticTexts?.homepage_service_link}
                </Link>
            </div>
            <div className={'serviceBlackCard'}>
                <div className={'serviceCard-icon'}>
                    <BlackEllipseIcon/>
                </div>
                <div className={'serviceCard-title'}>
                    {services[2]?.title}
                </div>
                <div className={'serviceCard-description'}>
                    {services[2]?.subTitle}
                </div>
                <Link to={getUrlWithLocal('services')} className={'serviceCard-more'}>
                    {staticTexts?.homepage_service_link}
                </Link>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'services',
        'staticTexts',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(ServiceBlock);