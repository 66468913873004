import {useEffect, useState} from "react";
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetDistribution,
    GetLanguages, GetOurProgram,
    GetStaticTexts,
    GetPartners, GetHome,
    GetServices,
    GetAbout,
    GetContacts
} from "../redux/actions";
import {connect} from "react-redux";
import moment from "moment";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import {useLocation, useNavigate} from "react-router-dom";
import {LOCALES} from '../constants/constType'


function Layout(props) {
    const {children, languages, selectedLanguage, isOpen} = props
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [changeLanguage, setChangeLanguage] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();
    function toggleMobileMenu() {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    function toggleLanguage() {
        setChangeLanguage(!changeLanguage)
    }

    useEffect(() => {
        props.GetStaticTexts().finally(() => {
            props.GetLanguages();
        });
    }, [])
    useEffect(() => {
        moment.locale(selectedLanguage)
        props.GetPartners()
        props.GetHome()
        props.GetServices()
        props.GetDistribution()
        props.GetOurProgram()
        props.GetAbout()
        props.GetContacts()
        // props.GetContacts()


        const pathname = location.pathname;
        const currLang = pathname?.split('/')[1]
        const page = pathname?.split('/')[2]
        if (LOCALES.includes(currLang)) {
            if (currLang !== selectedLanguage) {
                const pathnameWithoutLang = pathname?.slice(3)
                console.log(pathnameWithoutLang);
                navigate(`/${selectedLanguage}${pathnameWithoutLang}`, {replace: true})
            }else if(!page){
                navigate(`/${selectedLanguage}/home`, {replace: true})
            }
        } else {
            navigate(`/${selectedLanguage}/home`, {replace: true})
        }
    }, [selectedLanguage])
    return <div className={`layout-wrapper ${isOpen ? 'fixed' : ''}`}>
        <Header mobileMenuOpen={mobileMenuOpen}
                changeLanguage={changeLanguage}
                toggleLanguage={toggleLanguage}
                toggleMobileMenu={toggleMobileMenu}/>
        <MobileMenu className={mobileMenuOpen ? 'expand_on' : ''}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}/>
        <div>
            {children}
        </div>
        <Footer mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'categories',
        'staticTexts',
        'menuList',
        'blogs',
        'isOpen'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetStaticTexts,
    GetDistribution,
    GetOurProgram,
    GetPartners,
    GetHome,
    GetServices,
    GetAbout,
    GetContacts,
    // GetBlogs,
    // GetContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
