import React, {useState} from "react";

//Import Assets
import "./NewsCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {NewsArrIcon} from "../../../../assets/images";
import moment from "moment";
import NewsModal from "../../modal/newsModal/NewsModal";

function NewsCard(props){
    const {item} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [news,setNews] = useState()

    function toggleModalOpen(news){
        setNews(news)
        setModalOpen(!modalOpen)
    }

    return <div className={'news-card'} onClick={() => toggleModalOpen(item)}>
        <div className={'news-card-img'}>
            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
        </div>
        <div className={'news-card-info'}>
            <div className={'news-card-title-wrapper'}>
                <div className={'news-card-title'}>
                    {item?.title}
                </div>
                <div className={'news-card-link'} onClick={() => toggleModalOpen()}>
                    <NewsArrIcon/>
                </div>
            </div>
            <div className={'news-card-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
        </div>
        <NewsModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} news={news}/>
    </div>
}
export default NewsCard