import './service.scss'
import PageHeader from "../../components/pageHeader/PageHeader";
import {
    AboutPageImg,
    AboutResponsiveImg, DownloadIcon,
    HeaderArrIcon, IlInfoIcon, IlServicesIcon,
    LargeCubIcon, LargeEllipseIcon,
    MediumCubIcon, ServiceResponsiveImg,
    SmallCubIcon, SmallEllipseIcon
} from "../../assets/images";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAbout} from "../../redux/actions";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {useEffect} from "react";
import {WEB_URL} from "../../redux/api";

function Service(props){
    const {services,distribution,staticTexts} = props
    const serviceHeader = {
        responsiveImg:ServiceResponsiveImg,
        subTitle: `${staticTexts?.service_page_subtitle}`,
        title:`${staticTexts?.service_page_title}`,
        description:`${staticTexts?.service_page_description}`,
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function pdfFile(pdf) {
        const pdfLink = `${WEB_URL}/resources/${pdf}`
        if (pdf) {
            window.open(pdfLink, '_blank');
        }
    }

    return <div className={'service-wrapper'}>
        <PageHeader data={serviceHeader} icon={<IlServicesIcon/>}/>
        <div className={'service-blocks'}>
            <div className={'service-blocks-title'}>
                {staticTexts?.service_page_blocks_title}
            </div>
            <div className={'service-blocks-description'}>
                {staticTexts?.service_page_blocks_description}
            </div>
            <div className={'service-first-block'}>
                <div className={'first-block-info'}>
                    <div className={'first-block-title'}>
                        {services[0]?.title}
                    </div>
                    <div className={'first-block-description'} dangerouslySetInnerHTML={{__html: services[0]?.description}}/>
                    {
                        services[0]?.links && services[0]?.links.map(link => {
                            return <a href={link} target={'_blank'} className={'first-block-more'}>
                                {staticTexts?.service_page_blocks_connect} <HeaderArrIcon/>
                            </a>
                        })
                    }
                    {/*{*/}
                    {/*    services[0]?.pdfs && services[0]?.pdfs.map(pdf => {*/}
                    {/*        return <div className={'second-block-download'} onClick={() => pdfFile(pdf?.path)}>*/}
                    {/*            <DownloadIcon/> {staticTexts?.service_page_blocks_download}*/}
                    {/*        </div>*/}
                    {/*    })*/}
                    {/*}*/}
                </div>
                <div className={'first-block-images'}>
                    <div className={'small-cub-wrapper'}>
                        <SmallCubIcon/>
                    </div>
                    <div className={'medium-cub-wrapper'}>
                        <MediumCubIcon/>
                    </div>
                    <div className={'large-cub-wrapper'}>
                        <LargeCubIcon/>
                    </div>
                </div>
            </div>
            <div className={'service-distribution-block'}>
                <div className={'service-distribution-info'}>
                    {staticTexts?.service_page_distribution_info}
                </div>
                <div className={'service-distribution-wrapper'}>
                    {distribution?.length && distribution.map(item =>{
                        return <div className="service-distribution-item" key={item.id}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                        </div>
                    })}
                </div>
            </div>
            <div className={'service-blocks-wrapper'}>
                {
                    services?.length && services?.slice(1)?.map(item => {
                        return <div className={'service-second-block'}>
                            <div className={'second-block-info'}>
                                <div className={'second-block-title'}>
                                    {item?.title}
                                </div>
                                <div className={'second-block-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                                {
                                    item?.links && item?.links.map(link => {
                                        return <a href={link} target={'_blank'} className={'second-block-more'}>
                                            {staticTexts?.service_page_blocks_connect} <HeaderArrIcon/>
                                        </a>
                                    })
                                }
                                {
                                    item?.pdfs && item?.pdfs.map(pdf => {
                                        return <div className={'second-block-download'} onClick={() => pdfFile(pdf?.path)}>
                                            <DownloadIcon/> {staticTexts?.service_page_blocks_download}
                                        </div>
                                    })
                                }
                            </div>
                            <div className={'second-block-images'}>
                                <div className={'large-ellipse'}>
                                    <LargeEllipseIcon/>
                                </div>
                                <div className={'small-ellipse'}>
                                    <SmallEllipseIcon/>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'services',
        'distribution',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetAbout
};


export default  connect(mapStateToProps, mapDispatchToProps)(Service);