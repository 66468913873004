import {store} from "../redux/store";

export function getUrlWithLocal(path = "") {
    let pathname = path;
    if(!path.startsWith('/')) {
        pathname = "/"+path
    }
    const {selectedLanguage} = store?.getState().utils || {}
    const selectedCode = selectedLanguage || 'hy'
    // console.log(selectedCode,'selectedCode')
    return path ? `/${selectedCode}${pathname}` : `/${selectedCode}/home`
}