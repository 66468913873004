import './distributorBlock.scss'
import {HomeImg, SquareFirstIcon, SquareSecondIcon} from "../../../assets/images";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";

function DistributorBlock(props){
    const {home,staticTexts} = props

    return <div className={'distributorBlock-wrapper'}>
        <div className={'top-square'}>
            <SquareSecondIcon/>
        </div>
        <div className={'center-square'}>
            <SquareFirstIcon/>
        </div>
        <div className={'distributor-header'}>
            <div className={'distributor-header-left'}>
                <div className={'distributor-header-subtitle'}>
                    {staticTexts?.homepage_about}
                </div>
                <div className={'distributor-header-title'}>
                    {home?.title}
                </div>
            </div>
            <div className={'distributor-header-right'}>
                <div className={'distributor-header-description'}>
                    {home?.description}
                </div>
                <Link to={getUrlWithLocal('/news')} className={'distributor-header-link'}>
                    {staticTexts?.homepage_more}
                </Link>
            </div>
        </div>
        <div className={'distributorBlock-img'}>
            <img src={generateImageMediaUrl(home?.mediaMain?.path)} alt=""/>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'home',
        'staticTexts',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(DistributorBlock);