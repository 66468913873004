import './pageHeader.scss'
import {PageHeaderEllIcon, PageHeaderIcon} from "../../assets/images";
import {useLocation} from "react-router-dom";

function PageHeader({data,icon}) {
    const location = useLocation()
    // console.log(icon,'iconicon')

    return <div className={'pageHeader-wrapper'}>
        <div className={'page-responsive-img'}>
            <img src={data?.responsiveImg} alt=""/>
        </div>
        <div className={'pageHeader-left_block'}>
            <div className={'pageHeader-subtitle'}>
                <span>{data?.subTitle}</span>
            </div>
            <div className={'pageHeader-title'}>
                {data?.title}
            </div>
            <div className={'pageHeader-description'}>
                {data?.description}
            </div>
        </div>
        {
             <div className={'pageHeader_right_block'}>
                <div className={'pageHeader'}>
                    <PageHeaderIcon/>
                </div>
                <div className={'pageHeaderEll'}>
                    <PageHeaderEllIcon/>
                </div>
            </div>
        }
    </div>

}export default PageHeader