import './header.scss'
import {ChangeLanguage} from "../../redux/actions";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Link, NavLink} from "react-router-dom";
import {BurgerMenuIcon, CloseIcon, HeaderArrIcon, Logo} from "../../assets/images";
import {Dropdown,Menu} from "antd";
import {useEffect, useState} from "react";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


function Header(props){
    const {staticTexts,selectedLanguage,languages,toggleLanguage,mobileMenuOpen,toggleMobileMenu} = props;
    const [closeLanguage, setCloseLanguage] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll",() => setCloseLanguage(true))
    },[])

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       props.ChangeLanguage(item.code)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <div className={`header-wrapper`}>
        <div className={`header-white-block ${mobileMenuOpen ? 'fix-position' : ''}`}>
            <Link to={getUrlWithLocal('/home')}>
                <Logo/>
            </Link>
            <a className={`header_burger ${!mobileMenuOpen ? 'open-menu' : 'close-menu'}`}
               onClick={toggleMobileMenu}>
                {!mobileMenuOpen ? <BurgerMenuIcon/> : <CloseIcon/>}
            </a>
        </div>
        <div className={'header-black-block'}>
            <div className={'link-menu'}>
                <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_about}</li>
                </NavLink>
                <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_services}</li>
                </NavLink>
                <NavLink to={getUrlWithLocal('/news')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_news}</li>
                </NavLink>
                <NavLink to={getUrlWithLocal('/contact')} activeClassName='active'>
                    <li className="nav-contact">{staticTexts?.header_item_contact}<HeaderArrIcon/></li>
                </NavLink>
                <li className="nav-language">
                    <Dropdown overlay={menuLanguage}
                              trigger={['click']}
                              overlayClassName={`${closeLanguage ? "close" : ""}`}
                              onClick={() => toggleLanguage(setCloseLanguage(false))}
                              placement={'bottom'}>
                        <a className="ant-dropdown-link">
                            <div className="selected-language">
                                {
                                        languages?.length && languages?.map((item) => {
                                            if(selectedLanguage === item.code){
                                                return <span>{item?.name}</span>
                                            }
                                    })
                                }
                                <div>
                                </div>
                            </div>
                        </a>
                    </Dropdown>
                </li>
            </div>
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};
export default connect(mapStateToProps,mapDispatchToProps)(Header);