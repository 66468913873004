import {GENERAL_CONSTS} from "../constants";
import {store} from "../store";

export const initialState = {
    languages: [],
    distribution: {},
    isOpen: undefined,
    partners: [],
    ourPrograms: [],
    home: undefined,
    countryCode: 'en',

    staticTexts: {}, // selected
    translations: {}, // all
};

export default (state = initialState, action) => {

    switch (action.type) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const languages = action.payload.languages
            // const countryCode = action.payload.countryCode
            // const enLanguage = languages.find(lg => lg.code === 'en');
            // const selectedLanguage = state.selectedLanguage && state.selectedLanguage.id
            //     && languages.find(lg => lg.code === state.selectedLanguage.code);
            //
            // const countryLanguage = languages.find(lg => lg.code === countryCode);
            //
            // const newSelectedLanguage = selectedLanguage ? selectedLanguage :
            //     (countryLanguage ? countryLanguage : (enLanguage ? enLanguage : languages[0]));


            return {
                ...state,
                languages,
                // selectedLanguage: newSelectedLanguage,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                translations:action.payload.data,
                staticTexts: action.payload.data[action.payload?.selectedLanguage || "hy"]
            };
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                staticTexts: state?.translations?.[action.payload || "hy"]
            };

        case GENERAL_CONSTS.GET_DISTRIBUTION:
            return {
                ...state,
                distribution: action.payload
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_HOME:
            return {
                ...state,
                home: action.payload
            };
        case GENERAL_CONSTS.GET_OUR_PROGRAMS:
            return {
                ...state,
                ourPrograms: action.payload
            };
        case GENERAL_CONSTS.IS_OPEN:
            return {
                ...state,
                isOpen: action?.payload
            };
        default:
            return state;
    }
}
