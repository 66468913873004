import {getPropsFromState} from "../../redux/mapStateToProps";
import {useEffect} from "react";
import MainBlock from "../../components/homepage/mainBlock/MainBlock";
import {connect} from "react-redux";
import DistributorBlock from "../../components/homepage/distributorBlock/DistributorBlock";
import ProgramsBlock from "../../components/homepage/programsBlock/ProgramsBlock";
import ServiceBlock from "../../components/homepage/serviceBlock/ServiceBlock";
import ProjectBlock from "../../components/homepage/projectBlock/ProjectBlock";
import PartnerBlock from "../../components/homepage/partnerBlock/PartnerBlock";


function Homepage(props){
    const {partners, staticTexts,home,lastNews,contacts,requestLoading,services} = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'homepage-wrapper'}>
        <MainBlock/>
        <DistributorBlock/>
        <ProgramsBlock/>
        <ServiceBlock/>
        <ProjectBlock/>
        <PartnerBlock/>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
    ])
};

const mapDispatchToProps = {};


export default  connect(mapStateToProps, mapDispatchToProps)(Homepage);